import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const zoomAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1);
  }
`;

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1920px;

  @media (max-width: 1920px) {
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0px 65%;
  filter: grayscale(100%) sepia(50%);
  animation: ${zoomAnimation} 30s linear infinite;
`;

const GradientOverlay = styled.div`
  z-index: 1;  // Reduziert von 10 auf 1
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(53, 71, 88, 0.6), rgba(53, 71, 88, 0.6));
`;

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 20;
`;

const Logo = styled.img`
  width: 300px;
  margin-bottom: 20px;
  z-index: 30;
`;

const HiddenSEOText = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const HeroDeco = styled.div`
  background-color: #fcfcfc;
  position: absolute;
  bottom: 0;
  transition: height 0.3s ease-out;
  width: 2.5%;
  z-index: 2;  // Hinzugefügt, um sicherzustellen, dass es über dem Overlay liegt

  @media (min-width: 1024px) {
    width: 3rem;
  }
`;

const LeftDeco = styled(HeroDeco)`
  left: 0;
`;

const RightDeco = styled(HeroDeco)`
  right: 0;
`;

function Hero() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const decoHeight = Math.max(0, 50 + scrollPosition / 10);

  // Optimierte URLs mit unterschiedlichen Größen
  const optimizedHeroImage = {
    full: "https://res.cloudinary.com/dcjd8htwp/image/upload/f_auto,q_auto,w_1920/v1727654804/BioklaeranlagenGilge/mountains_jzkjuo.jpg",
    placeholder: "https://res.cloudinary.com/dcjd8htwp/image/upload/w_20,e_blur:1000,f_auto,q_auto/v1727654804/BioklaeranlagenGilge/mountains_jzkjuo.jpg"
  };

  const optimizedLogoImage = "https://res.cloudinary.com/dcjd8htwp/image/upload/f_auto,q_auto,w_300/v1727654818/BioklaeranlagenGilge/Biokl%C3%A4ranlagen_Gilge_White_logo_Transparent_bg_tlw6bw.svg";

  // Preload das Hero-Bild
  useEffect(() => {
    const preloadImage = new window.Image();
    preloadImage.src = optimizedHeroImage.full;
    preloadImage.onload = () => setImageLoaded(true);
  }, []);

  return (
    <HeroContainer>
      <GradientOverlay />

      {/* Placeholder Image (sofort sichtbar) */}
      <Image
        src={optimizedHeroImage.placeholder}
        alt=""
        loading="eager"
        style={{
          opacity: imageLoaded ? 0 : 1,
          filter: 'blur(10px)',
          transition: 'opacity 0.5s ease-in'
        }}
      />

      {/* Hauptbild */}
      <Image
        src={optimizedHeroImage.full}
        alt="Mountains"
        loading="eager"
        fetchpriority="high"
        style={{
          opacity: imageLoaded ? 1 : 0,
          transition: 'opacity 0.5s ease-in'
        }}
      />

      <Overlay>
        <Logo
          src={optimizedLogoImage}
          alt="Biokläranlagen Gilge. Na Klar!"
          loading="eager"
          fetchpriority="high"
        />
        <HiddenSEOText>
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-4">
            Biokläranlagen Gilge
          </h1>
          <p className="text-xl sm:text-2xl md:text-3xl">
            Ihre Experten für Kleinkläranlagen seit 2002
          </p>
        </HiddenSEOText>
      </Overlay>
      <LeftDeco style={{ height: `${decoHeight}%` }} />
      <RightDeco style={{ height: `${decoHeight}%` }} />
    </HeroContainer>
  );
}

export default Hero;