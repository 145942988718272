import React from 'react';
import SectionWrapper from './SectionWrapper';
import ResponsiveImage from './ResponsiveImage';

function About() {
  return (
    <section className="py-16 bg-background">
      <SectionWrapper>
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <h2 className="text-3xl font-bold text-primary mb-4">Über Biokläranlagen Gilge</h2>
              <p className="text-gray-700 leading-relaxed">
              Seit 2002 sind wir Ihr zuverlässiger Partner für vollbiologische Kleinkläranlagen. Unser Familienunternehmen, das mittlerweile in der zweiten Generation geführt wird, bietet langjährige Erfahrung und fachliche Kompetenz. Mit einem persönlichen Service gehen wir gezielt auf Ihre individuellen Bedürfnisse ein. Wir begleiten Sie von der ersten Planungsphase bis zur regelmäßigen Wartung Ihrer Anlage. Dabei legen wir großen Wert auf nachhaltige Lösungen, die sowohl umweltfreundlich als auch kosteneffizient sind. Unser engagiertes Team steht Ihnen bei allen Fragen zur Seite und sorgt dafür, dass Ihre Anlage stets optimal funktioniert.
              </p>
            </div>
            <div className="md:w-1/2 md:pl-8">
              <ResponsiveImage
                src="https://res.cloudinary.com/dcjd8htwp/image/upload/f_auto,q_auto,w_800,ar_1:1,c_fill,g_auto/v1727725717/BioklaeranlagenGilge/benno_franzi_xdh6pp.jpg"
                alt="Familie Gilge"
                maxWidth='80%'
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </SectionWrapper>
    </section>
  );
}

export default About;