import React, { useState, useEffect, useRef } from 'react';
import Gallery from '../components/Gallery';
import SectionWrapper from '../components/SectionWrapper';

const optimizeCloudinaryUrl = (url, width = 800) => {
  console.log('Original URL:', url);
  const optimizedUrl = url.replace('/upload/', `/upload/w_${width},f_auto,q_auto/`);
  console.log('Optimized URL:', optimizedUrl);
  return optimizedUrl;
};

const getThumbnailUrl = (url) => {
  console.log('Original URL:', url);
  const thumbnailUrl = url.replace('/upload/', '/upload/w_20,e_blur:1000,f_auto,q_auto/');
  console.log('Thumbnail URL:', thumbnailUrl);
  return thumbnailUrl;
};

// Testfunktion für ein einzelnes Bild
const testImageUrl = (url) => {
  fetch(url)
    .then(response => {
      console.log(`Status für ${url}:`, response.status);
    })
    .catch(error => {
      console.error(`Fehler beim Laden von ${url}:`, error);
    });
};

const projects = [
  {
    title: '25 EW Anlage in Klein Belitz',
    images: [
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654781/BioklaeranlagenGilge/20240920_093342_jyhlwa.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654781/BioklaeranlagenGilge/20240920_093342_jyhlwa.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654780/BioklaeranlagenGilge/20240920_093332_zjoctn.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654780/BioklaeranlagenGilge/20240920_093332_zjoctn.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654776/BioklaeranlagenGilge/20240920_093725_s10tce.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654776/BioklaeranlagenGilge/20240920_093725_s10tce.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654778/BioklaeranlagenGilge/20240920_114759_pj40wi.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654778/BioklaeranlagenGilge/20240920_114759_pj40wi.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654775/BioklaeranlagenGilge/20240920_122156_uzocx8.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654775/BioklaeranlagenGilge/20240920_122156_uzocx8.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654776/BioklaeranlagenGilge/20240920_080706_ictb88.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654776/BioklaeranlagenGilge/20240920_080706_ictb88.jpg"),
      },
    ],
  },
  {
    title: 'Regenentwässerung',
    images: [
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung5_yuuf0s.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung5_yuuf0s.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725716/BioklaeranlagenGilge/Regenentw%C3%A4sserung_v3b6r7.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725716/BioklaeranlagenGilge/Regenentw%C3%A4sserung_v3b6r7.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung4_kag8qk.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung4_kag8qk.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung3_xppfhu.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung3_xppfhu.jpg"),
      },
      {
        original: "https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung2_chfdbr.jpg",
        thumbnail: getThumbnailUrl("https://res.cloudinary.com/dcjd8htwp/image/upload/v1727725715/BioklaeranlagenGilge/Regenentw%C3%A4sserung2_chfdbr.jpg"),
      },
    ],
  }
];

function Galerie() {
  const [openAccordion, setOpenAccordion] = useState(0);
  const accordionRefs = useRef([]);

  useEffect(() => {
    // Öffnet das erste Accordion beim Laden der Seite
    setOpenAccordion(0);
  }, []);

  useEffect(() => {
    if (openAccordion !== null && accordionRefs.current[openAccordion]) {
      const yOffset = -200; // Anzahl der Pixel, um die nach oben verschoben werden soll
      const element = accordionRefs.current[openAccordion];
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [openAccordion]);

  useEffect(() => {
    // Test der ersten Bild-URL
    const firstImage = projects[0].images[0];
    console.log('Teste Bild-URLs:');
    testImageUrl(firstImage.original);
    testImageUrl(optimizeCloudinaryUrl(firstImage.original));
    testImageUrl(getThumbnailUrl(firstImage.original));
  }, []);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <section>
      <SectionWrapper>
        <div className="container mx-auto px-4 py-16">
          <h1 className="text-4xl font-extrabold text-center text-primary mb-12">Unsere Galerie</h1>

          {projects.map((project, index) => (
            <div
              key={index}
              className="mb-12 shadow-lg rounded-lg overflow-hidden"
              ref={el => accordionRefs.current[index] = el}
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="text-2xl font-semibold w-full text-left bg-primary text-white p-6 hover:bg-gradient-to-l transition duration-300 flex justify-between items-center"
              >
                {project.title}
                <span className="transform transition-transform duration-300">
                  {openAccordion === index ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  )}
                </span>
              </button>
              {openAccordion === index && (
                <div className="mt-4 p-4 bg-white">
                  <Gallery
                    images={project.images.map(img => {
                      const original = typeof img === 'string' ? img : img.original;
                      return {
                        original: original,
                        optimized: optimizeCloudinaryUrl(original),
                        thumbnail: getThumbnailUrl(original)
                      };
                    })}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </SectionWrapper>
    </section>
  );
}

export default Galerie;