import React, { useState, useEffect, useRef } from 'react';
import SectionWrapper from './SectionWrapper';

const Gallery = ({ images }) => {
  const [columns, setColumns] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const masonryRef = useRef(null);

  const brakePoints = [350, 500, 750];

  useEffect(() => {
    const handleResize = () => {
      if (masonryRef.current) {
        const newColumns = getColumns(masonryRef.current.offsetWidth);
        if (newColumns !== columns) {
          setColumns(newColumns);
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [columns]);

  const getColumns = (width) => {
    return brakePoints.reduceRight((p, c, i) => {
      return c < width ? p : i;
    }, brakePoints.length) + 1;
  };

  const mapChildren = () => {
    let col = [];
    for (let i = 0; i < columns; i++) {
      col.push([]);
    }
    return images.reduce((p, c, i) => {
      p[i % columns].push(c);
      return p;
    }, col);
  };

  const openModal = (image) => {
    setSelectedImage(image.optimized || image.original);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section className="gallery-section">
      <SectionWrapper>
        <div className="masonry-container">
          <div className="masonry" ref={masonryRef}>
            {mapChildren().map((col, ci) => (
              <div className="column" key={ci}>
                {col.map((image, i) => (
                  <div className="tile" key={i} onClick={() => openModal(image)}>
                    <img
                      src={image.optimized || image.original}
                      alt={`Gallery item ${i + 1}`}
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </SectionWrapper>
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <img src={selectedImage} alt="Full size" loading="lazy" />
        </div>
      )}
    </section>
  );
};

export default Gallery;